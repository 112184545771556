<template>
	<div>
		<div class="bbox" style="padding-right: 0;">
			
			<div class="flex-1 gg-container">
				<div class="search-container">
					<div class="search-container-fn-input" v-if="adminId === 1">
						<label>商户：</label>
						<el-select v-model="searchParams.agent_id"
											 clearable
											 size="small"
											 placeholder="请选择"
											 @change="getArticleList"
						>
							<el-option
								v-for="item in merchantsListDropDownData"
								:key="item.id"
								:label="item.merchant_name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					
					<div class="search-container-fn-input">
						<label>文章分类：</label>
						<el-select v-model="searchParams.cate_id"
											 clearable
											 size="small"
											 placeholder="请选择"
											 @change="getArticleList"
						>
							<el-option
								v-for="item in articleCate"
								:key="item.id"
								:label="item.title"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					
					
					<div class="search-container-fn-input">
						<label>文章标题：</label>
						<el-input
							size="small"
							placeholder="文章标题"
							prefix-icon="el-icon-search"
							clearable
							v-model="searchParams.keywords"
							@change="getArticleList"
						>
						</el-input>
					</div>
					<div class="search-container-fn-input">
						<label>添加时间：</label>
						<el-date-picker
							size="small"
							v-model="datetimerange"
							type="daterange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							value-format="yyyy-MM-dd"
							@change="dateChange">
						</el-date-picker>
					</div>
					
					<div class="search-container-fn-input">
						<label>状态：</label>
						<el-select v-model="searchParams.status" clearable placeholder="请选择" size="small"
											 @change="getArticleList">
							<el-option
								v-for="item in statusOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search-container-fn-input">
						<!--<el-button type="primary" size="mini" @click="getArticleList"-->
						<!--					 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;">查询-->
						<!--</el-button>-->
						<el-button type="primary" icon="el-icon-plus" size="mini" style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;"
											 @click="editArticle('add')">新增
						</el-button>
					</div>
				
				</div>
				
				<el-table
					v-loading="listLoading"
					:data="tableData"
					border
					style="width: 100%">
					<el-table-column
						prop="title"
						label="文章标题">
						<template slot-scope="{row}">
							<el-button @click="editArticle('view',row)" type="text" size="mini">{{row.title}}</el-button>
						</template>
					</el-table-column>
					
					<el-table-column
						prop="merchant_name"
						label="商户"
						width="220"
						align="center">
					</el-table-column>
					
					<el-table-column
						prop="created_at"
						label="创建时间"
						width="220"
						align="center"
					>
					</el-table-column>
					
					<el-table-column
						prop="cate_name"
						label="分类"
						width="220"
						align="center"
					>
					</el-table-column>
					<el-table-column
						prop="sort"
						label="排序"
						width="120"
						align="center"
					>
					</el-table-column>
					
					<el-table-column
						prop="status"
						align="center"
						label="商品状态"
						width="160">
						<template slot-scope="scope">
							<el-switch
								class="switch"
								@change="changeExamine(scope.row)"
								v-model="scope.row.status"
								:active-value="1"
								:inactive-value="0"
								active-text="启用"
								inactive-text="禁用"
								active-color="#13ce66"
								inactive-color="#C8C8C8">
							</el-switch>
						</template>
					</el-table-column>
					
					<el-table-column
						prop="address"
						label="操作"
						align="center"
						width="150"
						fixed="right">
						<template slot-scope="{row}">
							<el-button style="padding: 7px 8px;" icon="el-icon-edit" @click="editArticle('edit',row)" type="primary" size="mini">编辑</el-button>
							<el-button style="padding: 7px 8px;margin-left: 5px;" icon="el-icon-delete" type="danger" size="mini" @click="delArticle(row)">删除</el-button>
						</template>
					</el-table-column>
				
				
				</el-table>
				<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
										@pagination="getArticleList"/>
				<!--新增编辑 快捷回复-->
			
			</div>
		</div>
	
	
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {
  articleList,
  saveStatusArticle,
  delArticle, merchantsList
} from "@/api/article";
import {mapGetters} from 'vuex';

export default {
  name: "articleList",
  components: {
    Pagination
  },
  computed: {
    ...mapGetters(["adminId"]),
    dialogTitle () {
      return {
        'add': '添加',
        'edit': '编辑',
      }[this.openType] || '添加'
    },
  },
  activated () {
    this.getArticleList()
  },
  created () {
    this.getArticleList()
    this.getMerchantsList()
  },
  data () {
    return {
      datetimerange: '',
      searchParams: {},
      currInfo: {},
      openType: '',
      total: 0,
      articleCate: [
        {
          id: 31,
          title: "患者端文章",
        },
        {
          id: 37,
          title: "医生端文章",
        }
      ],
      merchantsListDropDownData: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 100,
        agent_id: '',
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      statusOptions: [{
        value: 1,
        label: '启用'
      }, {
        value: 0,
        label: '禁用'
      }],
      tableData: [],
      dialogCateVisible: false,
      cateDropDownData: [],
    }
  },
  methods: {
    getMerchantsList () {
      merchantsList().then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          console.log(_data)
          this.merchantsListDropDownData = _data
        }
      }).catch(error => {
        console.log(error);
      })
    },
    getArticleList (page) {
      this.listLoading = true
      if (!(page && page.page)) {
        this.listQuery.page = 1
      }
      let params = { ...this.searchParams }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      articleList(params).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = Number(_data.per_page)
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    editArticle (type, item) {
      this.openType = type
      if (type === 'add') {
        this.$router.push({
          path: '/articleManage/articleDetail',
          query: { type: type, }
        })

      } else {
        this.currInfo = { ...item }
        if (!item) {
          this.$message({
            type: 'error',
            message: '请选择要编辑的文章'
          });
          return
        }
        this.$router.push({
          path: '/articleManage/articleDetail',
          query: { type: type, article_id: item.article_id, agent_id: item.agent_id }
        })
      }
    },
    delArticle (item) {
      this.$confirm(`是否删除文章 "${item.title}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        let params = {}
        params.article_id = item.article_id
        delArticle(params).then(response => {
          let data = response
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getArticleList()
          } else {
            this.$message({
              type: 'error',
              message: data.msg + ',' + data.code
            });
          }

        }).catch(error => {
          console.log(error);
        })

      }).catch(() => {

      });
    },
    changeExamine (row) {  //选择状态 (启用/禁用)
      console.log(row.status)
      this.listLoading = true
      const data = {
        article_id: row.article_id,
        type: row.status
      }
      saveStatusArticle(data).then(response => {
        let data = response
        console.log(data)
        if (data.code == 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          // this.getArticleList()
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    },
    reset () {
      this.searchParams = {}
      this.listQuery.page = 1
    },
    // 监听日期选择
    dateChange (val) {
      console.log(val)
      if (val && val.length) {
        this.searchParams.start_at = val[0]
        console.log(val[0])
        this.searchParams.end_at = val[1]
      } else {
        this.searchParams.start_at = ''
        this.searchParams.end_at = ''
      }
      this.getArticleList()
    },
  },
}
</script>

<style scoped lang="scss">
	.treeBox {
		width: 280px;
		flex-shrink: 0;
		margin-right: 20px;
		padding: 0;
		
		.btnBox {
			i {
				margin-left: 5px;
				font-size: 20px;
				cursor: pointer;
			}
		}
	}
	
	.px-2 {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	.py-1 {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	
	.py-2 {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	
	.p-2 {
		padding: 20px;
	}
	
	.p-1 {
		padding: 10px;
	}
	
	.border-bottom {
		border-bottom: 1px solid #ececec;
	}
	
	.dot {
		display: inline-block;
		border: 5px solid;
		border-radius: 50%;
		margin: 0 5px;
	}
	
	.custom-tree-node {
		font-size: 12px;
		
		.btnWrap {
			i {
				font-style: normal;
				margin-left: 5px;
			}
		}
	}
	
	.filter-tree {
		margin-top: 10px;
	}
	
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
